import { ParentComponent, createEffect } from "solid-js";
import { useIsRouting, createAsync } from "@solidjs/router";
import { useSiteContext } from "~/utils/contexts";
import { getBuilderGlobalData } from "~/services/builder";
import Nav from "../header/Nav";
import Footer from "../Footer";

const Layout: ParentComponent = (props) => {
  const {
    headerVisible: [headerVisible],
  } = useSiteContext();
  const global = createAsync(async () => getBuilderGlobalData());
  const loading = useIsRouting();

  return (
    <>
      <div
        id="header-navigation"
        class="sticky top-0 z-20 transition-transform duration-400"
        classList={{
          "-translate-y-full": !headerVisible(),
        }}
      >
        <Nav
          show={() => true} // not even used ?
          structure={
            global()?.header.data.blocks[0].component.options.navigation
          }
        />
      </div>

      {props.children}

      <Footer
        navigation={
          global()?.footer.data.blocks[0].component.options.navigation
        }
        jobs={global()?.jobs}
      />
    </>
  );
};

export default Layout;
